(function ($, window) {
  $tabMenuAccordion = $('.tim-tab-menu-accordion');
  if ($tabMenuAccordion.length > 0) {
    let count = 1;
    $tabMenuAccordion.each(function (i, el) {
      $(el).find('a[href^="#tab-menu"]').each(function (index, element) {
        const href = $(element).attr('href');
        const tab = $(element).closest('.tabBox').find(href);
        $(element).attr('href', `#tab-menu${count}`);
        tab.attr('id', `tab-menu${count}`);
        count++;
      });
    });

    $tabMenuAccordion.each(function (i, el) {
      $(el).find("ul.tabs li:first").addClass("active").show();
      $(el).find(".tabContent:first").show();

      $(el).find("ul.tabs li").on('click touch', function (e) {
        e.preventDefault();
        $(el).find("ul.tabs li").removeClass("active");
        $(this).addClass("active");
        $(el).find(".tabContent").hide();
        var activeTab = $(this).find("a").attr("href");
        $(el).find(activeTab).fadeIn();
        return false;
      });
    });

  }
})(jQuery, window);
