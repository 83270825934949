(function ($, window) {
  $resultSearchNews = $('.tim-news-template');
  if ($resultSearchNews.length > 0) {
    $newsFilters = $resultSearchNews.find('.tim-news-filters');
    $cptType = $resultSearchNews.attr('data-type');
    $form = $resultSearchNews.find('form');
    $resultSearchNews.each(function (i, el) {

      $pagination = $(el).find('.tim-pagination');
      var url = ajax_url,
        data = {
          nextPage: 1,
          action: 'paginationNews',
          cptType: $cptType,
          postsPerPage: $resultSearchNews.attr('data-post-per-page')
        };

      $.post(url, data, function (resp) {
        var respObj = JSON.parse(resp);
        pagination(1, parseInt(respObj.maxNumPages));
      });

      $(el).on('click touch', '.tim-page', function (e) {
        e.preventDefault();

        const order = $form.find('input[name=sort-order]').val();
        const terms = $form.find('input[name=category-order]').val();

        var url = ajax_url,
          data = {
            nextPage: parseInt($(e.target).html()),
            action: 'paginationNews',
            cptType: $cptType,
            order: order,
            terms: terms,
            postsPerPage: $resultSearchNews.attr('data-post-per-page')
          };

        $.post(url, data, function (resp) {
          var respObj = JSON.parse(resp);
          $resultWrapper = $resultSearchNews.find('.tim-results').find('.tim-resuls-wrapper');
          $resultWrapper.empty();
          $resultWrapper.html(respObj.markup);
          $pagination.attr('data-curr-page', respObj.nextPage);
          $pagination.find('.tim-page').remove();
          pagination(parseInt(respObj.nextPage), parseInt(respObj.maxNumPages));
          $(e.target).addClass('active');
          if (respObj.nextPage === '1')
            $pagination.find('.tim-prev').addClass('disabled');
          else
            $pagination.find('.tim-prev').removeClass('disabled');
          if (respObj.hasNext)
            $pagination.find('.tim-next').removeClass('disabled');
          else
            $pagination.find('.tim-next').addClass('disabled');

          $('html, body').animate({
            scrollTop: $newsFilters.offset().top - 100
          }, 1000);
        });
      });

      $(el).on('click touch', '.tim-prev', function (e) {
        e.preventDefault();

        const order = $form.find('input[name=sort-order]').val();
        const terms = $form.find('input[name=category-order]').val();

        var url = ajax_url,
          data = {
            nextPage: parseInt($pagination.attr('data-curr-page')) - 1,
            action: 'paginationNews',
            cptType: $cptType,
            order: order,
            terms: terms,
            postsPerPage: $resultSearchNews.attr('data-post-per-page')
          };

        $.post(url, data, function (resp) {
          var respObj = JSON.parse(resp);
          $resultWrapper = $resultSearchNews.find('.tim-resuls-wrapper');
          $resultWrapper.empty();
          $resultWrapper.html(respObj.markup);
          $pagination.attr('data-curr-page', respObj.nextPage);
          $pagination.find('.tim-page').remove();
          pagination(parseInt(respObj.nextPage), parseInt(respObj.maxNumPages));
          $pagination.find('.tim-page:contains(' + respObj.nextPage + ')').addClass('active');
          if (respObj.nextPage === '1')
            $pagination.find('.tim-prev').addClass('disabled');
          else
            $pagination.find('.tim-prev').removeClass('disabled');
          if (respObj.nextPage === '1' && respObj.hasNext)
            $pagination.find('.tim-next').removeClass('disabled');
          else
            $pagination.find('.tim-next').addClass('disabled');

          $('html, body').animate({
            scrollTop: $newsFilters.offset().top - 100
          }, 1000);

          return false;
        });
      });

      $(el).on('click touch', '.tim-next', function (e) {
        e.preventDefault();

        const order = $form.find('input[name=sort-order]').val();
        const terms = $form.find('input[name=category-order]').val();

        var url = ajax_url,
          data = {
            nextPage: parseInt($pagination.attr('data-curr-page')) + 1,
            action: 'paginationNews',
            cptType: $cptType,
            order: order,
            terms: terms,
            postsPerPage: $resultSearchNews.attr('data-post-per-page')
          };

        $.post(url, data, function (resp) {
          var respObj = JSON.parse(resp);
          $resultWrapper = $resultSearchNews.find('.tim-resuls-wrapper');
          $resultWrapper.empty();
          $resultWrapper.html(respObj.markup);
          $pagination.attr('data-curr-page', respObj.nextPage);
          $pagination.find('.tim-page').remove();
          pagination(parseInt(respObj.nextPage), parseInt(respObj.maxNumPages));
          $pagination.find('.tim-page:contains(' + respObj.nextPage + ')').addClass('active');
          if (respObj.hasNext)
            $pagination.find('.tim-next').removeClass('disabled');
          else
            $pagination.find('.tim-next').addClass('disabled');

          $pagination.find('.tim-prev').removeClass('disabled');

          $('html, body').animate({
            scrollTop: $newsFilters.offset().top - 100
          }, 1000);

          return false;
        });
      });

      $newsFilters.on('click touch MDCSelect:change', '.mdc-select', function (e) {
        e.preventDefault();
        $form = $newsFilters.find('form');

        const order = $form.find('input[name=sort-order]').val();
        const terms = $form.find('input[name=category-order]').val();

        var url = ajax_url,
          data = {
            nextPage: 1,
            action: 'paginationNews',
            cptType: $cptType,
            order: order,
            terms: terms,
            postsPerPage: $resultSearchNews.attr('data-post-per-page')
          };

        $.post(url, data, function (resp) {
          var respObj = JSON.parse(resp);
          $resultWrapper = $resultSearchNews.find('.tim-resuls-wrapper');
          $resultWrapper.empty();
          $resultWrapper.html(respObj.markup);
          $pagination.attr('data-curr-page', respObj.nextPage);
          $pagination.find('.tim-page').remove();
          $resultSearchNews.find('.tim-button.show-more-mobile').attr('data', 2);
          if (respObj.maxNumPages > 0) {
            pagination(1, parseInt(respObj.maxNumPages));
            if (respObj.hasNext) {
              $pagination.find('.tim-next').removeClass('disabled');
              $resultSearchNews.find('.tim-button.show-more-mobile').show();
            } else {
              $pagination.find('.tim-next').addClass('disabled');
              $pagination.find('.tim-prev').addClass('disabled');
              $resultSearchNews.find('.tim-button.show-more-mobile').hide();
            }
          } else {
            $pagination.find('.tim-prev').addClass('disabled');
            $pagination.find('.tim-next').addClass('disabled');
            $("<a class=\"tim-page text label2 medium\" href=\"#anchor\">No match found</a>").insertBefore($pagination.find('.tim-next'));
          }

          $('html, body').animate({
            scrollTop: $newsFilters.offset().top - 100
          }, 1000);

          return false;
        });
      });

      // Bottone mostra di più visibile solo su mobile
      $resultSearchNews.on('click', '.tim-button.show-more-mobile', function (e) {
        e.preventDefault();
        $form = $newsFilters.find('form');

        const order = $form.find('input[name=sort-order]').val();
        const terms = $form.find('input[name=category-order]').val();

        var url = ajax_url,
          data = {
            nextPage: $(this).attr('data'),
            action: 'paginationNews',
            cptType: $cptType,
            order: order,
            terms: terms,
            postsPerPage: $resultSearchNews.attr('data-post-per-page')
          };

        $.post(url, data, function (resp) {
          var respObj = JSON.parse(resp);
          $resultWrapper = $resultSearchNews.find('.tim-resuls-wrapper');
          $resultWrapper.append(respObj.markup);
          $showMoreButton = $resultSearchNews.find('.tim-button.show-more-mobile');
          $showMoreButton.attr('data', parseInt($showMoreButton.attr('data')) + 1);
          if (!respObj.hasNext)
            $showMoreButton.hide();

          return false;
        });
      });

      // Pagination
      function pagination(c, m) {
        var current = c,
          last = m,
          delta = 1,
          left = current - delta,
          right = current + delta + 1,
          range = [],
          rangeWithDots = [],
          l;

        for (let i = 1; i <= last; i++) {
          if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
          }
        }

        for (let i of range) {
          if (l) {
            if (i - l === 2) {
              $page = $("<a class=\"tim-page" + ((l + 1) === c ? 'active' : '') + "\" href=\"#anchor\">" + (l + 1) + "</a>");
              $page.insertBefore($pagination.find('.tim-next'));
              rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
              $page = $("<a class=\"tim-page disabled\" href=\"#anchor\">...</a>");
              $page.insertBefore($pagination.find('.tim-next'));
              rangeWithDots.push('...');
            }
          }
          $page = $("<a class=\"tim-page " + (i === c ? 'active' : '') + "\" href=\"#anchor\">" + i + "</a>");
          $page.insertBefore($pagination.find('.tim-next'));
          rangeWithDots.push(i);
          l = i;
        }
        return rangeWithDots;
      }

      function refreshResult() {
        $resultSearchNews.find('.tim-page:first').click();
      }

      var transposed = "desktop";
      $(document).ready(function () {
        if ($(window).width() < 1024) {
          transposed = "mobile";
        } else {
          transposed = "desktop";
        }
      })

      $(window).resize(function () {
        if ($(window).width() > 1024 && transposed === "mobile") {
          transposed = "desktop";
          refreshResult();
        } else if ($(window).width() < 1024 && transposed === "desktop") {
          transposed = "mobile";
          refreshResult();
        }
      })
    });
  }
})(jQuery, window);
