(function (window, document, $) {
  function resetDrawers() {
    $('#right-drawer').removeClass('active');
    /* $('#overlay').fadeOut();*/
    $('.tim-header-menu').removeClass('mobile-open');
  }

  $(window).on('resize', () => {
    if ($(window).width() >= 1024) {
      $header = $('.tim-header-menu');
      $header.find('.menu-trigger').removeClass('button-trigger');
      //$('#overlay').fadeOut();
      $header.removeClass('mobile-open');
      $header.find('#right-drawer').removeClass('active');
    }
  });

  $(document).ready(() => {
    $('.menu-trigger').on('click', function (e) {
      if (!$(this).hasClass('button-trigger')) {
        resetDrawers()
        $(this).addClass('button-trigger');
        $('.tim-header-menu').addClass('mobile-open');
        //$('#overlay').fadeIn();
        $('#right-drawer').addClass('active');
      } else {
        $(this).removeClass('button-trigger');
        /* $('#overlay').fadeOut();*/
        $('.tim-header-menu').removeClass('mobile-open');
        $('#right-drawer').removeClass('active');
      }
    });

    function toggleMenu(e) {
        open = $(e.currentTarget).attr('aria-expanded') === 'true';

        if(open) {
            closeMenu(e);
        } else {
            openMenu(e);
        }
    }

    function openMenu(e) {

        // prima chiudo tutti i menu
        $('.tim-header-menu .tim-main-menu').siblings('.tim-secondary-menu').stop().hide();
        $('.tim-header-menu .tim-main-menu').attr('aria-expanded', 'false');

        if ($(e.currentTarget).is('.tim-secondary-menu')) {
            $(e.currentTarget).stop().show();
            $(e.currentTarget).siblings('.tim-main-menu').attr('aria-expanded', 'true');
        } else {
            $(e.currentTarget).siblings('.tim-secondary-menu').stop().show();
            $(e.currentTarget).attr('aria-expanded', 'true');
        }
        /* $('#overlay').show();*/
    }

    function closeMenu(e) {
        // se sono nel menu secondo livello non deve fare slideUp
        if ($(e.currentTarget).is('.tim-secondary-menu')) {
            $(e.currentTarget).stop().hide();
            $(e.currentTarget).siblings('.tim-main-menu').attr('aria-expanded', 'false');
        } else {
            $(e.currentTarget).siblings('.tim-secondary-menu').stop().hide();
            $(e.currentTarget).attr('aria-expanded', 'false');
        }
        /* $('#overlay').hide(); */
    }

    $('.tim-header .tim-right .menu-item-has-children, .tim-header .tim-secondary-menu').on('mouseenter', openMenu).on('mouseleave', closeMenu);
    $('.tim-header .tim-right .menu-item-has-children').on('click', toggleMenu);

    $('.tim-accordion-menu-right-drawer').on('click touch', (e) => {
      const $accordion = $(e.target).closest('.tim-accordion-menu-right-drawer');
      /* $accordion.siblings('li').toggleClass('hide'); */
      $accordion.toggleClass('active');
    })
  });
})
(window, document, jQuery);
