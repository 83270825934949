(function (window, document, $) {

  $(document).ready(function () {
      $headerSimplified = $('.tim-header-simplified');

      if ($headerSimplified.length > 0) {
        $headerSimplified.find('.tim-right a').on('click touch', function (e) {
          e.preventDefault();
          $.modal.close();
          $('#header-simplified-modal').modal({
            fadeDuration: 100,
            fadeDelay: 0.50,
            closeExisting: true
          });
        });
      }
    }
  )
})(window, document, jQuery);
