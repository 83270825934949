/* NPM COMPONENTS */
import 'jquery-modal';
import 'material-components-web';
import 'jquery-validation';
import 'owl.carousel';
import 'devbridge-autocomplete/dist/jquery.autocomplete';

/* general */
import './_general';

/* Components */
import '../../components/_components';

/* layouts */
import '../../layouts/education-template/education-template';
import '../../layouts/enhancers-footer-builder-template/enhancers-footer-builder-template';
import '../../layouts/preventivatore/preventivatore';
