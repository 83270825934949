(function (window, document, $) {
  if ($('.tim-flip-countdown').length > 0) {
    $(document).ready(function () {
      $counter = $('.tim-flip-countdown');
      const daysElement = $counter.find('#days');
      const hoursElement = $counter.find('#hours');
      const minutesElement = $counter.find('#minutes');
      const secondsElement = $counter.find('#seconds');

      function updateCountdown() {
        const targetDate = new Date($counter.attr('data-countdown'));
        // const targetDate = new Date('03/15/2024 16:18:00 GMT+00:00');

        const now = new Date().getTime();
        const timeDifference = targetDate - now;

        if (timeDifference <= 0) {
          // Countdown is over
          daysElement.text('00');
          hoursElement.text('00');
          minutesElement.text('00');
          secondsElement.text('00');
        } else {
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

          daysElement.text(formatTime(days));
          hoursElement.text(formatTime(hours));
          minutesElement.text(formatTime(minutes));
          secondsElement.text(formatTime(seconds));
        }
      }

      function formatTime(time) {
        return time < 10 ? `0${time}` : time;
      }

      // Initial update
      updateCountdown();

      // Update every second
      setInterval(updateCountdown, 1000);
    });
  }
})(window, document, jQuery);
