(function (window, document, $) {
    $(document).ready(function () {
        $accordionMenuRightDrawer = $('.tim-accordion-menu-right-drawer');
        $allPanel = $accordionMenuRightDrawer.find('.tim-title');
        if ($accordionMenuRightDrawer.length > 0) {
            $accordionMenuRightDrawer.find('.tim-title').on('click touch', function (e) {
                e.preventDefault();
                if (!$(this).hasClass('active')) {
                    $allPanel.removeClass('active');
                    $allPanel.siblings().slideUp();
                    $allPanel.find('.tim-icon').removeClass('animate');
                    $(this).addClass('active');
                    $(this).siblings().slideDown();
                } else {
                    $(this).removeClass('active');
                    $(this).siblings().slideUp();
                }
                $(this).parent().find('.tim-icon').toggleClass('animate');

            });
        }
    })
})(window, document, jQuery);
