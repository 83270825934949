(function (window, document, $) {

  $(document).ready(function () {
    $toggleSliderComponent = $('.tim-toggle-slider');
    if ($toggleSliderComponent.length > 0) {

      $toggleSliderComponent.on('set_value', function (e, newValue) {
        const $slider = $(this);
        const $input = $slider.find('input');
        $input.val(newValue).change();
      });

      $toggleSliderComponent.find('input[type="range"]').rangeslider({

        // Feature detection the default is `true`.
        // Set this to `false` if you want to use
        // the polyfill also in Browsers which support
        // the native <input type="range"> element.
        polyfill: false,

        rangeClass: 'rangeslider',
        disabledClass: 'rangeslider--disabled',
        horizontalClass: 'rangeslider--horizontal',
        fillClass: 'rangeslider__fill',
        handleClass: 'rangeslider__handle',

        // Callback function
        onInit: function () {
          $rangeEl = this.$range;
          // add value label to handle
          var $handle = $rangeEl.find('.rangeslider__handle');
          var handleValue = '<div class="rangeslider__handle__value">' + this.value + '</div>';
          $handle.append(handleValue);

        },

        // Callback function
        onSlide: function (position, value) {
          var $handle = this.$range.find('.rangeslider__handle__value');
          $handle.text(this.value);
        },

        // Callback function
        onSlideEnd: function (position, value) {
          $toggleSliderComponent.trigger('value_changed', $toggleSliderComponent.find('input').val());
        }
      });
    }
  });

})(window, document, jQuery);
