// This example adds a search box to a map, using the Google Place Autocomplete
// feature. People can enter geographical searches. The search box will return a
// pick list containing a mix of places and predicted search terms.
// This example requires the Places library. Include the libraries=places
// parameter when you first load the API. For example:
// <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">
(function ($, window, ajax_url) {


  if ($('.tim-store-locator').length > 0) {

    // window.mdc.autoInit();

    // Autocomplete
    document.addEventListener('DOMContentLoaded', function () {
      var input = document.getElementById('search_location');
      var currentLocationButton = document.getElementById('current_location_button');
      currentLocationButton.addEventListener('click', getCurrentLocation);
      var autocomplete = new google.maps.places.Autocomplete(input);
    });

    // Marker
    var tim_marker = {
      url: template + '/assets/img/tim.png',
      scaledSize: new google.maps.Size(40, 40)
    };

    var santander_marker = {
      url: template + '/assets/img/santander.png',
      scaledSize: new google.maps.Size(40, 40)
    };

    var marker_list = [], map;
    var infowindow;
    loadMarkerAndSetMap();

    $('#santander-net-checkbox, #centri-tim-checkbox').on('change', function (e) {
      e.preventDefault();
      loadMarkerAndSetMap();
    })

    // DISTANCE AND NEAREST MARKER SELECTOR
    var geocoder = new google.maps.Geocoder();
    let marker;

    $('.tim-find-store').next().on('click touch', function (e) {
      e.preventDefault();

      if ($(e.target).length > 0) {
        var lat = '';
        var lng = '';
        var address = document.getElementById('search_location').value;

        // Place Marker for searched place
        geocoder.geocode({'address': address}, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (marker) marker.setMap(null);
            map.setCenter(results[0].geometry.location);
            marker = new google.maps.Marker({
              map: map,
              position: results[0].geometry.location
            });

            lat = results[0].geometry.location.lat();
            lng = results[0].geometry.location.lng();

            if (marker_list.length > 0) {

              //console.log(marker_list[findNearestMarker(lat,lng)]);
              var nearest_marker = marker_list[findNearestMarker(lat, lng)];

              var distanceK = distance(lat, lng, nearest_marker.getPosition().lat(), nearest_marker.getPosition().lng(), 'K');

              // chiudo tutti i markers
              for (var i = 0; i < marker_list.length; i++) {
                marker_list[i].infowindow.close();
              }

              map.panTo(nearest_marker.getPosition());
              map.setZoom(15);
              nearest_marker.infowindow.open(nearest_marker.get('map'), nearest_marker);
            }

          } else console.log("Geocode was not successful for the following reason: " + status);
        });
      }
      return false;
    });

    function rad(x) {
      return x * Math.PI / 180;
    }

    function findNearestMarker(lat, lng) {
      var R = 6371; // radius of earth in km
      var distances = [];
      var closest = -1;
      for (i = 0; i < marker_list.length; i++) {
        var mlat = marker_list[i].position.lat();
        var mlng = marker_list[i].position.lng();
        var dLat = rad(mlat - lat);
        var dLong = rad(mlng - lng);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        distances[i] = d;
        if (closest == -1 || d < distances[closest]) {
          closest = i;
        }
      }
      return closest;
    }

    function distance(lat1, lon1, lat2, lon2, unit) {
      if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
      } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") {
          dist = dist * 1.609344
        }
        if (unit === "N") {
          dist = dist * 0.8684
        }
        return dist;
      }
    }

    // Funzione per ottenere la posizione corrente e con Google Autocomplete valorizzarla nel campo di input
    function getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var geocoder = new google.maps.Geocoder();
          var latlng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          geocoder.geocode({'location': latlng}, function (results, status) {
            if (status === 'OK') {
              if (results[0]) {
                var address = results[0].formatted_address;
                document.getElementById('search_location').value = address;
              } else {
                console.log('Nessun risultato trovato');
              }
            } else {
              console.log('Geocoder ha fallito con errore: ' + status);
            }
          });
        }, function () {
          console.log('Geolocalizzazione fallita.');
        }, {timeout: 10000});
      } else {
        console.log('Il browser non supporta la geolocalizzazione.');
      }
    }

    // Set iniziale di mappa e Markers
    function loadMarkerAndSetMap() {
      var url = ajax_url;
      var data = {
        action: 'getGoogleMapsMarkers',
        centriTimCheckbox: $('#centri-tim-checkbox').prop("checked"),
        santanderNetCheckbox: $('#santander-net-checkbox').prop("checked")
      };

      $.post(url, data, function (resp) {
        var respJson = JSON.parse(resp);

        marker_list = [];

        // CREATE MAP AND DEFINE ELEMENT TO DISPLAY
        map = new google.maps.Map(document.getElementById('map'), {
          scrollwheel: false,
          zoom: 8,
          styles: [
            {
              "featureType": "landscape.natural",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "visibility": "on"
                },
                {
                  "color": "#e0efef"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry.fill",
              "stylers": [
                {
                  "visibility": "on"
                },
                {
                  "hue": "#1900ff"
                },
                {
                  "color": "#c0e8e8"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                {
                  "lightness": 100
                },
                {
                  "visibility": "simplified"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry",
              "stylers": [
                {
                  "visibility": "on"
                },
                {
                  "lightness": 700
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "all",
              "stylers": [
                {
                  "color": "#7dcdcd"
                }
              ]
            }
          ]
        });

        respJson.forEach(val => {
          var marker = new google.maps.Marker({
            title: val.title,
            id: val.id,
            clickable: true,
            draggable: false,
            position: new google.maps.LatLng(parseFloat(val.lat), parseFloat(val.long)),
            map: map,
            icon: val.icon === 'tim' ? tim_marker : santander_marker
          });
          marker_list.push(marker);
        });

        var thePanorama = map.getStreetView();

        // change view
        google.maps.event.addListener(thePanorama, 'visible_changed', function () {
          // Display your street view visible UI
          if (thePanorama.getVisible()) {

            var panorama = new google.maps.StreetViewPanorama(
              document.getElementById('map'), {
                enableCloseButton: true,
                addressControlOptions: {
                  position: google.maps.ControlPosition.BOTTOM_CENTER, // <- change position
                }
              });
            // rewrite default options
            map.setStreetView(panorama);

          } else {
            console.log('show default UI');
          }
        });

        var bounds = new google.maps.LatLngBounds();

        // Verifica se marker_list ha almeno un elemento
        if (marker_list.length > 0) {

          map.setCenter({lat: marker_list[0].getPosition().lat(), lng: marker_list[0].getPosition().lng()});

          // FIT DISPLAY TO CONTAIN ALL MARKERS

          for (var i = 0; i < marker_list.length; i++) {
            bounds.extend(marker_list[i].getPosition());
            attachMarkerMessage(marker_list[i], respJson[i].tooltip);
          }
          map.fitBounds(bounds);

        } else {
          map.setCenter({lat: 41.871941, lng: 12.567380});
          map.setZoom(6);
        }

        // ATTACH DATA AND MESSAGE TO MARKERS
        function attachMarkerMessage(marker, message) {
          infowindow = new google.maps.InfoWindow({
            content: message
          });

          marker.infowindow = infowindow;

          google.maps.event.addListener(marker, 'click', function () {
            this.infowindow.open(this.get('map'), this);
          });
        }

      });
    }
  }

})(jQuery, window, ajax_url);
