(function (window, document, $) {

  $(document).ready(function () {

    const isOpenNow = function() {
      const now = new Date();
      const day = now.getDay(); // 0 = Domenica, 1 = Lunedì, ..., 6 = Sabato
      const hour = now.getHours(); // 0 - 23
      const minute = now.getMinutes(); // 0 - 59

      // Funzione per controllare se l'ora attuale è compresa tra due orari
      function isTimeInRange(startHour, startMinute, endHour, endMinute) {
        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;
        const currentTime = hour * 60 + minute;
        return currentTime >= startTime && currentTime <= endTime;
      }

      // Dal lunedì al venerdì
      if (day >= 1 && day <= 5) {
        return isTimeInRange(9, 0, 21, 0);
      }

      // Il sabato
      if (day === 6) {
        return isTimeInRange(9, 0, 13, 0);
      }

      // La domenica è chiuso
      return false;
    }

      const reloadDataTable = function (importo, cb) {
        const data = new FormData();
        data.append("action", 'update_preventivatore');
        data.append("importo", importo);
        $.ajax({
          type: "POST",
          enctype: 'multipart/form-data',
          url: ajax_url,
          data: data,
          processData: false,
          contentType: false,
          success: function (result) {
            let $res = JSON.parse(result);
            window.preventivatore_data_table = $res;
            if (cb) { cb($res); }
            // prima disattivo le card
            // $cards = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card');
            // $cards.find('.tim-rates-not-available').show();
            // $cards.find('.tim-rates').hide();
            // $cards.find('.tim-note-title').hide();
            // $cards.find('.tim-note-description').hide();
            // $cards.find('.tim-radio').attr('disabled', 'true').prop('checked', false);
            // $preventivatore.find('.tim-step-3').fadeOut();
            // $preventivatore.find('.tim-assicurazione-detail').fadeOut();
            // $mobileSections = $preventivatore.find('.tim-notes-mobile');
            // $mobileSections.find('.tim-note-title').hide();
            // $mobileSections.find('.tim-note-description').hide();
            // // resetto le variabili nurtigo
            // window.tan = '';
            // window.taeg = '';
            // window.rata = '';
            // window.con_ass = false;
            //
            // $res.forEach(function (item) {
            //   // console.log('Item:');
            //   // console.log(item);
            //
            //   if (item['has_ass'] && item['rata'] !== '0,00€') {
            //     $card = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:first');
            //     $mobileSection = $preventivatore.find('.tim-notes-mobile:first');
            //     $card.find('.tim-note-description').html(item.disclaimer);
            //     $card.find('.tim-rates .rata').html(item.rata);
            //     $card.find('.tan').html(item.tan);
            //     $card.find('.taeg').html(item.taeg);
            //     $card.find('.tim-rates-not-available').hide();
            //     $card.find('.tim-rates').show();
            //     $card.find('.tim-note-title').show();
            //     $card.find('.tim-note-description').show();
            //     $mobileSection.find('.tim-note-title').show();
            //     $mobileSection.find('.tim-note-description').show();
            //     $mobileSection.find('.tim-note-description').html(item.disclaimer);
            //     $card.find('.tim-radio').removeAttr('disabled');
            //     // window.tan_assicurazione = $card.find('.tan').html();
            //     // window.taeg_assicurazione = $card.find('.taeg').html();
            //     // window.rata_assicurazione = $card.find('.rata').html();
            //   } else if(item['rata'] !== '0,00€') {
            //     $card = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:last');
            //     $mobileSection = $preventivatore.find('.tim-notes-mobile:last');
            //     $card.find('.tim-note-description').html(item.disclaimer);
            //     $card.find('.tim-rates .rata').html(item.rata);
            //     $card.find('.tan').html(item.tan);
            //     $card.find('.taeg').html(item.taeg);
            //     $card.find('.tim-rates-not-available').hide();
            //     $card.find('.tim-rates').show();
            //     $card.find('.tim-note-title').show();
            //     $card.find('.tim-note-description').show();
            //     $mobileSection.find('.tim-note-title').show();
            //     $mobileSection.find('.tim-note-description').show();
            //     $mobileSection.find('.tim-note-description').html(item.disclaimer);
            //     $card.find('.tim-radio').removeAttr('disabled');
            //     // window.tan_no_assicurazione = $card.find('.tan').html();
            //     // window.taeg_no_assicurazione = $card.find('.taeg').html();
            //     // window.rata_no_assicurazione = $card.find('.rata').html();
            //   }
            // });
          }
        });
      }

      const refreshRates = function (data) {
        const $rate = $('.tim-rates .rate');
        const $tan = $('.tim-rates .tan');
        const $taeg = $('.tim-rates .taeg');
        const $disclaimer = $('.tim-note-description');
        $rate.html(data.num_rate);
        $tan.html(data.tan);
        $taeg.html(data.taeg);
        $disclaimer.html(data.disclaimer);
      }

      const formatValue = function(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
      }

      window.onNurtigoFormSubmitSuccess = function() {
        updateUrl({'step': 'thankyou'});
        const $step1_summary = $preventivatore.find('.tim-step-1-summary');
        $step1_summary.addClass('hide');
        const $step2_summary = $preventivatore.find('.tim-step-2-summary');
        $step2_summary.addClass('hide');
        const $step1 = $preventivatore.find('.tim-step-1');
        $step1.addClass('hide');
        const $step2 = $preventivatore.find('.tim-step-2');
        $step2.addClass('hide');
        const $step3 = $preventivatore.find('.tim-step-3');
        $step3.fadeOut().addClass('hide');
        const $thanyou = $preventivatore.find('.tim-thank-you-template');
        $thanyou.fadeIn();
        scrollToElement($thanyou);
      }

      const updateUrl = function(params) {
        if (history.pushState) {
          const newurl = updateQueryParam(window.location.href, params);
          window.history.pushState({path:newurl},'', newurl);
        }
      }

      const $preventivatore = $('.tim-preventivatore');
      if ($preventivatore.length > 0) {

        // $preventivatore.on('touchstart mousedown', function(evt) {
        //   preventDoubleTapZoom(evt);
        // });

        if (!isOpenNow()) {
          $preventivatore.find("#chiama-ora").hide.prev().hide();
        }


        // function limitScroll() {
        //   const maxScrollOffset = $('.tim-header-menu').height() + 44;
        //   console.log("========================");
        //   console.log(maxScrollOffset);
        //   console.log(window.scrollY);
        //   if (window.scrollY < maxScrollOffset) {
        //     window.scrollTo(0, maxScrollOffset);
        //     // $('html').animate(
        //     //   {
        //     //     scrollTop: maxScrollOffset,
        //     //   },
        //     //   400
        //     // );
        //   }
        // }
        // window.addEventListener('scroll', limitScroll);

        if (window.preventivatore_input_data.importo) {
          const $step2 = $preventivatore.find('.tim-step-2');
          scrollToElement($step2, 44 + 32);
          updateUrl({'step': 2});
        } else {
          updateUrl({'step': 1});
        }
        window.preventivatore_input_data.importo = window.preventivatore_input_data.importo ?? '10000';
        window.preventivatore_input_data.durata = window.preventivatore_input_data.durata ?? '48';
        window.importo = window.preventivatore_input_data.importo;
        window.durata = window.preventivatore_input_data.durata;
        reloadDataTable(window.preventivatore_input_data.importo, function() {

          // Setup pickers
          // =====================================
          const $importoPicker = $preventivatore.find('#importo');
          $importoPicker.trigger('init', {
            'custom_display_fn': function (num) {
              return formatValue(num);
            }
          });
          $importoPicker.on('value_changed', function(e, importo) {
            window.importo = importo;
            updateUrl({'importo': importo});
            $preventivatore.find('#step_1_summary .tim-step-summary-value').html(formatValue(importo));
            if (window.importo_picker_debounce_timout) { clearTimeout(window.importo_picker_debounce_timout); }
            window.importo_picker_debounce_timout = setTimeout(function() {
              reloadDataTable(importo, function() {
                const $durataPicker = $preventivatore.find('#durata');
                $durataPicker.trigger('refresh_view');
              });
            }, 300);
          });

          const $durataSlider = $preventivatore.find('#durata_slider');
          $durataSlider.on('value_changed', function(e, durata) {
            window.durata = durata;
            updateUrl({'durata': durata});
            const $durataPicker = $preventivatore.find('#durata');
            $durataPicker.trigger('set_value', durata);
          });

          const $durataPicker = $preventivatore.find('#durata');
          $durataPicker.trigger('init', {
            'custom_display_fn': function (num) {
              const filtered = window.preventivatore_data_table ? window.preventivatore_data_table.filter((row) => {
                return row.num_rate == num;
              }) : [];
              let ret = '- €';
              if (filtered.length) {
                refreshRates(filtered[0]);
                ret = formatValue(filtered[0].rata);
              }
              $preventivatore.find('#step_2_summary .tim-step-summary-value').html(ret);
              return ret;
            }
          });
          $durataPicker.on('value_changed', function(e, durata) {
            window.durata = durata;
            updateUrl({'durata': durata});
            const $durataSlider = $preventivatore.find('#durata_slider');
            $durataSlider.trigger('set_value', durata);
          });
        });


        // window.tan_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:last .tan').html();
        // window.taeg_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:last .taeg').html();
        // window.rata_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:last .rata').html();
        // window.tan_no_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:first .tan').html();
        // window.taeg_no_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:first .taeg').html();
        // window.rata_no_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:first .rata').html();

        $preventivatore.find('#step_1_summary').on('click touch', function (evt) {
          evt.preventDefault();
          updateUrl({
            'importo': null,
            'durata': null,
            'step': 1
          });
          const $step1_summary = $preventivatore.find('.tim-step-1-summary');
          $step1_summary.fadeOut().addClass('hide');
          const $step2_summary = $preventivatore.find('.tim-step-2-summary');
          $step2_summary.fadeOut().addClass('hide');
          const $step1 = $preventivatore.find('.tim-step-1');
          $step1.fadeIn().removeClass('hide');
          const $step2 = $preventivatore.find('.tim-step-2');
          $step2.fadeOut().addClass('hide');
          const $step3 = $preventivatore.find('.tim-step-3');
          $step3.fadeOut().addClass('hide');
          scrollToElement($step1);
        });

        $preventivatore.find('#step_2_summary').on('click touch', function (evt) {
          evt.preventDefault();
          updateUrl({'step': 2});
          const $step2_summary = $preventivatore.find('.tim-step-2-summary');
          $step2_summary.fadeOut().addClass('hide');
          const $step2 = $preventivatore.find('.tim-step-2');
          $step2.fadeIn().removeClass('hide');
          const $step3 = $preventivatore.find('.tim-step-3');
          $step3.fadeOut().addClass('hide');
          scrollToElement($step2, 44 + 32);
        });

        $preventivatore.find('#step_1_cta').on('click touch', function (evt) {
          evt.preventDefault();
          updateUrl({
            'importo': window.importo,
            'durata': window.durata,
            'step': 2
          });
          const $step1_summary = $preventivatore.find('.tim-step-1-summary');
          $step1_summary.css("display", "flex").fadeIn().removeClass('hide');
          const $step1 = $preventivatore.find('.tim-step-1');
          $step1.fadeOut().addClass('hide');
          const $step2 = $preventivatore.find('.tim-step-2');
          $step2.fadeIn().removeClass('hide');
          scrollToElement($step2, 44 + 32);
        });

        $preventivatore.find('#step_2_cta').on('click touch', function (evt) {
          evt.preventDefault();
          updateUrl({'step': 3});
          const $step2_summary = $preventivatore.find('.tim-step-2-summary');
          $step2_summary.css("display", "flex").fadeIn().removeClass('hide');
          const $step2 = $preventivatore.find('.tim-step-2');
          $step2.fadeOut().addClass('hide');
          const $step3 = $preventivatore.find('.tim-step-3');
          $step3.fadeIn().removeClass('hide');
          scrollToElement($step3, 88 + 32);
        });

        $preventivatore.find("#ti-chiamiamo").on('click', function (e) {
          e.preventDefault();
          updateUrl({'step': 'form'});
          const $step4 = $preventivatore.find('.tim-step-3-detail.tim-nurtigo-form');
          $step4.fadeIn(function() {
            $step4.show();
            scrollToElement($step4);
          });
        });
        const $step4Close = $preventivatore.find('.tim-step-3-detail.tim-nurtigo-form .tim-nurtigo-form-back');
        $step4Close.on('click', function(e) {
          e.preventDefault();
          const $step4 = $preventivatore.find('.tim-step-3-detail.tim-nurtigo-form');
          $step4.hide();
        });

        $preventivatore.find("#chiama-ora .overlay").on('click', function(e) {
          window.location = 'tel:01119888886'
        });
      }
    }
  )
})(window, document, jQuery);
