(function (window, document, $) {

  $(document).ready(function () {


    const reloadDataTable = function (importo, cb) {
      const data = new FormData();
      data.append("action", 'update_preventivatore');
      data.append("importo", importo);
      $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: ajax_url,
        data: data,
        processData: false,
        contentType: false,
        success: function (result) {
          let $res = JSON.parse(result);
          window.preventivatore_data_table = $res;
          if (cb) { cb($res); }
        }
      });
    }

    const refreshRates = function (data) {
      const $simulaPrestito = $('.tim-simula-prestito');
      $simulaPrestito.next().html(data.disclaimer);
    }

    const formatValue = function(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
    }

    const $simulaPrestito = $('.tim-simula-prestito');

    if ($simulaPrestito.length > 0) {

      reloadDataTable(10000, function() {
        //refreshView();

        // Setup pickers
        // =====================================
        const $importoPicker = $simulaPrestito.find('#importo');
        $importoPicker.trigger('init', {
          'custom_display_fn': function (num) {
            return formatValue(num);
          }
        });
        $importoPicker.on('value_changed', function(e, importo) {
          $simulaPrestito.find('#step_1_summary .tim-step-summary-value').html(formatValue(importo));
          if (window.importo_picker_debounce_timout) { clearTimeout(window.importo_picker_debounce_timout); }
          window.importo_picker_debounce_timout = setTimeout(function() {
            reloadDataTable(importo, function() {
              const $durataSlider = $simulaPrestito.find('#durata_slider');
              $durataSlider.trigger('value_changed', $durataSlider.find('input').val());
            });
          }, 300);
        });

        const $durataSlider = $simulaPrestito.find('#durata_slider');
        $durataSlider.on('value_changed', function(e, durata) {
          const filtered = window.preventivatore_data_table ? window.preventivatore_data_table.filter((row) => {
            return row.num_rate == durata;
          }) : [];
          let ret = '- €';
          if (filtered.length) {
            refreshRates(filtered[0]);
            ret = formatValue(filtered[0].rata);
          }
        });
        // $durataPicker.on('value_changed', function(e, durata) {
        //   window.durata = durata;
        //   const $durataSlider = $simulaPrestito.find('#durata_slider');
        //   $durataSlider.trigger('set_value', durata);
        // });
      });



      $simulaPrestito.find('.tim-button').on('click touch', function (evt) {
        evt.preventDefault();
        var paramString = '';
        var stopRedirect = false;
        $simulaPrestito.find('input').each(function () {
          if ( $(this).attr('name') === 'importo' ) {
            paramString += '?' + $(this).attr('name') + '=' + $(this).attr('data-real-value');
          } else {
            paramString += '&' + $(this).attr('name') + '=' + $(this).val();
          }
        });
        if ( !stopRedirect ) {
          location.href = $(this).attr('href') + paramString;
        }
      });

      // $simulaPrestito.find("input[name=durata], input[name=importo]").on('change', function (e) {
      //   e.preventDefault();
      //   var data = new FormData($simulaPrestito.find('.tim-form')[0]);
      //   data.append("action", 'update_preventivatore');
      //   $.ajax({
      //     type: "POST",
      //     enctype: 'multipart/form-data',
      //     url: ajax_url,
      //     data: data,
      //     processData: false,
      //     contentType: false,
      //     success: function (result) {
      //       $res = JSON.parse(result);
      //       $simulaPrestito.next().html($res[1].disclaimer);
      //     }
      //   });
      // });
    }
  })
})(window, document, jQuery);
