(function ($, window) {
  $tabContainer = $('.tim-tab-container');
  if ($tabContainer.length > 0) {
    $tabContainer.find(".tabs-content").eq(0).show();
    $tabContainer.find("#tabs li").eq(0).addClass("active");
    $tabContainer.find("#tabs li").click(function (e) {
      e.preventDefault()
      var number = $(this).index();
      $tabContainer.find("#tabs li").removeClass("active");
      $tabContainer.find(".tabs-content").hide().eq(number).fadeIn("slow");
      $tabContainer.find("#tabs li").eq(number).addClass("active");
    });

    $(document).ready(function () {
      if (window.location.hash !== "") {
        $tabContainer.find("#tabs " + window.location.hash).click();
      }
    });

  }
})(jQuery, window);
