(function (window, document, $) {
  var findStorePanel = $('.tim-find-store.autocomplete');
  if (findStorePanel.length > 0) {
      $(document).ready(function () {
          $(findStorePanel).find('input').autocomplete({
              serviceUrl: ajax.baseUrl,
              showNoSuggestionNotice: true,
              paramName: 'searchString',
              noSuggestionNotice: 'Nessun risultato trovato',
              params: {'action': 'autocompleteStore'},
              onSelect: function (suggestion) {
                  location.href = suggestion.data;
                  return false;
              }
          });
      });
  }
})(window, document, jQuery)
