(function (window, document, $) {

  $(document).ready(function () {
      if ($('.form-builder-widget').length > 0) {

        window.mdc.autoInit();

        $form = $('.form-builder-widget form');

        $form.each(function (i, elem) {

          $(elem).find('.tim-button').on('click touch', (e) => {
            if (typeof $(e.target).closest('.tim-button').attr('disabled') !== 'undefined') return false;
          });

          $(elem).on('submit', function (e) {

            e.preventDefault();

            var data = new FormData($(elem)[0]);
            data.append("action", $(elem).attr('action'));
            $dataHasThankyou = $(elem).attr('data-thankyou');
            data.append("file", $(elem).closest('.tim-modal').attr('data'));
            data.append("referrer", document.referrer);

            if ($(e.target).valid()) {
              $.ajax({
                type: "POST",
                enctype: 'multipart/form-data',
                url: ajax_url,
                data: data,
                processData: false,
                contentType: false,
                success: function (result) {
                  if ($dataHasThankyou) {
                    location.href = $dataHasThankyou;
                    return false;
                  } else {
                    $.modal.close();
                    // form_submit used for sendcv
                    // newsletter_subscription used for newsletter
                    $confirmDialog = $(elem).attr('action') === 'form_submit' ? $('#contact-us-modal' + '-' + $(elem).find('.tim-form-submit-button').attr('data')) : $('#newsletter-modal');
                    $confirmDialog.modal({
                      fadeDuration: 100,
                      fadeDelay: 0.50,
                      closeExisting: true
                    });

                    // Only for zapier integration
                    $zapier = $(elem).find('.tim-button').attr('data-zapier');
                    if (typeof $zapier != 'undefined' && $zapier.length > 0) {
                      // Asyncronous AJAX call to Zapier URL
                      $.ajax({
                        type: "POST",
                        enctype: 'multipart/form-data',
                        url: $zapier,
                        data: data,
                        processData: false,
                        contentType: false,
                        success: function (data) {
                        }
                      });
                    }
                  }
                },
                error: function (e) {
                }
              });
            } else {
              $(e.target).validate();
              return false;
            }
          });

          var validator = $(this).validate({
            ignore: "",
            submitHandler: function (form) {
            },
            errorPlacement: function (error, element) {
              error.insertBefore(element.closest('label'));
              try {
                $(element).closest('.tim-text-field-wrapper').siblings('label.tim-form-element-label').addClass('error');
              } catch (error) {
              }
            },
            success: function (element) {
              $(element).closest('.tim-text-field-wrapper').siblings('label.tim-form-element-label').removeClass('error');
              $(element).remove();
            },
            rules: {
              messaggio: {
                maxlength: 100
              }
            }
          });

          $(elem).on('input', 'input[name*=n_telefono]', function (e) {
            text = $(e.target).val();
            $(e.target).val(text.replace(/\D*(\d{3})\D*(\d{4})\D*(\d{4})\D*/, '$1 $2 $3'));
          })

          $(elem).find('input[name=privacy_policy]').closest('.mdc-touch-target-wrapper').addClass('tim-privacy-policy');

          const select = document.querySelector('.mdc-select');
          if (select) {
            select.addEventListener('MDCSelect:change', e => {
              $(e.target).find('input[type=hidden]').trigger('change');
            });
          }
          $(elem).on('keyup change', 'input[required], textarea[required]', checkValidity);
          $(elem).on('change', 'select.required, input[type="checkbox"][required]', checkValidity);

          function checkValidity(event, token) {

            if (typeof event !== 'undefined') {
              if ($(elem).validate().element($(event.target))) {
                $(event.target).closest('label').removeClass('invalid');
              } else {
                $(event.target).closest('label').addClass('invalid');
              }
            }

            var emptyInput = $(event.target).closest('form').find('input[required], textarea[required], select.required').map(function (index, el) {
              return (!$(el).val() || $(el).hasClass('error')) ? el : null;
            }).get();

            var emptyCheckbox = $(event.target).closest('form').find('input[required][type="checkbox"]').map(function (index, el) {
              return !$(el).is(':checked') ? el : null;
            }).get();

            // var emptyRadio = $(event.target).closest('form').find('input[required][type="radio"]:checked').length;

            var emptyRadio = true;
            $(event.target).closest('form').find('input[required][type="radio"]').each(function () {
              var name = $(this).attr("name");
              if ($(elem).find("input:radio[required][name=" + name + "]:checked").length === 0) {
                emptyRadio = false;
              }
            });

            if (emptyInput.length === 0 && emptyCheckbox.length === 0 && emptyRadio) {
              $(elem).find('.tim-form-submit-button').removeAttr('disabled');
            } else {
              $(elem).find('.tim-form-submit-button').attr('disabled', true);
            }
          }

          var globalFunctions = {};

          globalFunctions.ddInput = function (el) {
            if ($(el).length === 0 || typeof FileReader === "undefined") return;
            //var $fileupload = $('input[type="file"]');
            var $file = $(elem).find('input[type=file]');
            var noitems = '<li class="no-items">' + $file.attr('data-hint') + '</li>';
            var otherHint = '<div class="other-hint">' + $file.attr('data-other-hint') + '</div>';
            var file_list = '<ul class="file-list"></ul>';
            var rmv = '<div class="remove"></div>'

            $file.each(function () {
              var self = this;
              var $dropfield = $('<div class="drop-field"><div class="drop-area"></div></div>');
              $(self).after($dropfield).appendTo($dropfield.find('.drop-area'));
              var $button = $dropfield.find('.tim-button');
              var $file_list = $(file_list).appendTo($dropfield);
              // $dropfield.append(hasitems);
              $dropfield.append(rmv);
              $(noitems).appendTo($file_list);
              $(otherHint).appendTo($file_list);
              var isDropped = false;
              $(self).on("change", function (evt) {
                if ($(self).val() === "") {
                  $file_list.find('li').remove();
                  $file_list.append(noitems);
                  // $file_list.append(otherHint);
                } else {
                  ///$button.hide();
                  if (!isDropped) {
                    $dropfield.removeClass('hover');
                    $dropfield.addClass('loaded');
                    var files = $(self).prop("files");
                    traverseFiles(files);
                  }
                }
              });

              $dropfield.on("dragleave", function (evt) {
                $dropfield.removeClass('hover');
                //$button.show();
                evt.stopPropagation();
              });

              $dropfield.on('click touch', function (evt) {
                $(self).val('');
                //$button.show();
                $file_list.find('li').remove();
                $file_list.find('div').remove();
                $file_list.append(noitems);
                $file_list.append(otherHint);
                // $file_list.append(otherHint);
                $dropfield.css('height', '124px');
                $dropfield.removeClass('hover').removeClass('loaded');
              });

              $(elem).find('.drop-field').on('click touch', '.tim-button', (e) => {
                $file = $(e.target).closest('.drop-area').find('input[type=file]');
                $file.focus();
                $file.click();
              });

              $dropfield.on("dragenter", function (evt) {
                $dropfield.addClass('hover');
                evt.stopPropagation();
              });

              $dropfield.on("drop", function (evt) {
                isDropped = true;
                $dropfield.removeClass('hover');
                $dropfield.addClass('loaded');
                var files = evt.originalEvent.dataTransfer.files;
                traverseFiles(files);
                isDropped = false;
                //$button.show();
              });

              function appendFile(file) {
                //console.log(file);
                $file_list.append('<li>' + file.name + '</li>');
              }

              function traverseFiles(files) {
                if ($dropfield.hasClass('loaded')) {
                  $file_list.find('li').remove();
                }
                if (typeof files !== "undefined") {
                  for (var i = 0, l = files.length; i < l; i++) {
                    appendFile(files[i]);
                    $dropfield.css('height', ($dropfield.find('.file-list').innerHeight() + 80) + 'px');
                  }
                } else {
                  console.log("No support for the File API in this web browser");
                }
              }
            });
          };
          globalFunctions.ddInput('input[type="file"]');

        })
        ; // end form each

        $.extend($.validator.messages, {
          required: 'Campo obbligatorio',
          email: "Inserisci un indirizzo email valido",
          url: "Inserisci un URL valido",
          remote: "Please fix this field",
          maxlength: jQuery.validator.format("Per favore non inserire più di {0} caratteri"),
          /*
          date: abp.localization.localize("FormValidationMessageDate"),//"Please enter a valid date.",
          dateISO: "Please enter a valid date (ISO).",
          number:  abp.localization.localize("FormValidationMessageNumber"),//"Please enter a valid number.",
          digits: "Please enter only digits.",
          creditcard: "Please enter a valid credit card number.",
          equalTo:  abp.localization.localize("FormValidationMessageDataEquals"),//"Please enter the same value again.",
          accept: "Please enter a value with a valid extension.",

          minlength: jQuery.validator.format(abp.localization.localize("FormValidationMessageMinlength")),//jQuery.validator.format("Please enter at least {0} characters."),
          rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
          range: jQuery.validator.format("Please enter a value between {0} and {1}."),
          max: jQuery.validator.format(abp.localization.localize("FormValidationMessageMax")),//jQuery.validator.format("Please enter a value less than or equal to {0}."),
          min: jQuery.validator.format(abp.localization.localize("FormValidationMessageMin"))//jQuery.validator.format("Please enter a value greater than or equal to {0}.")
           */
        });

        jQuery.validator.addMethod("exactlength", function (value, element, param) {
          return this.optional(element) || value.length === param;
        }, $.validator.format("Il campo deve contenere {0} caratteri"));
      }

    }
  )
})(window, document, jQuery);
