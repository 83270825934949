(function (window, document, $) {
  var findStorePanel = $('.tim-find-faq');
  if (findStorePanel.length > 0) {

    $(document).ready(function () {
      $(findStorePanel).find('.tim-button').on('click touch', (e) => {
        e.preventDefault();
        var data = new FormData(findStorePanel[0]);
        data.append("action", findStorePanel.attr('action'));
        $text = findStorePanel.find('input#input_faq').val();
        if ($text.length > 0) {
          data.append("text", $text);
          $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: ajax_url,
            data: data,
            processData: false,
            contentType: false,
            success: function (result) {
              findStorePanel.siblings('.tim-faq-result').empty().html(result);
            }
          });
        }
      });
      window.mdc.autoInit();
    });
  }
})(window, document, jQuery)
